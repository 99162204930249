import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config

const Aula = () => {
  const [aulas, setAulas] = useState([]);
  const [verDisponibles, setVerDisponibles] = useState(true);
  const [busqueda, setBusqueda] = useState('');

  useEffect(() => {
    const fetchAulas = async () => {
      try {
        const response = await axios.get(`${config.baseURL}/api/v1/aula`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setAulas(response.data.data);
      } catch (error) {
        console.error("Error obteniendo las aulas", error);
      }
    };

    fetchAulas();
  }, []);

  const aulasFiltradas = aulas.filter(aula => aula.disponible === verDisponibles && aula.nombreAula.toLowerCase().includes(busqueda.toLowerCase()));

  const handleBusquedaChange = (event) => {
    setBusqueda(event.target.value);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
          <Header />
      <div className="grid place-items-center mx-2 my-20 sm:my-auto">
        <div className="w-2/3 mt-4 p-12  2xl:w-11/12 px-6 py-10 sm:px-10 sm:py-6 bg-white rounded-lg shadow-md lg:shadow-lg ">
          <div className="text-center mb-4">
            <h1 className="font-semibold text-[#0A6A50] text-3xl">Aulas</h1>
          </div>
          <div className="flex justify-between mb-4">
            <button
              className={`px-4 py-2 ${verDisponibles ? "bg-[#0A6A50] text-white" : "bg-gray-200 text-gray-800"} rounded-md`}
              onClick={() => setVerDisponibles(true)}
            >
              Aulas Disponibles
            </button>
            <input
              type="text"
              id="busqueda"
              name="busqueda"
              placeholder="Buscar aula"
              className="ml-2 mr-2 py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
              value={busqueda}
              onChange={handleBusquedaChange}
            />
            <button
              className={`px-4 py-2 ${!verDisponibles ? "bg-[#0A6A50] text-white" : "bg-gray-200 text-gray-800"} rounded-md`}
              onClick={() => setVerDisponibles(false)}
            >
              Aulas No Disponibles
            </button>

         
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
            {aulasFiltradas.length > 0 ? (
              aulasFiltradas.map((aula) => (
                <div key={aula.id} className="p-4 bg-white rounded-md shadow-md">
                  <h3 className="text-lg font-semibold">{aula.nombreAula}</h3>
                  <p>Capacidad: {aula.capacidad}</p>
                  <p>Tipo: {aula.tipoAula}</p>
                </div>
              ))
            ) : (
              <p className="col-span-full text-center">
                No hay aulas {verDisponibles ? "disponibles" : "no disponibles"}.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aula;

