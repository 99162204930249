import React, { useState, useEffect } from 'react';
import Header from './Header';
import axios from 'axios';
import moment from 'moment-timezone';
import BitacoraRegistros from './BitacorasRegistros';
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config

const Bitacora = () => {
  const [bitacoras, setBitacora] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]);
  const [Personal, setPersonal] = useState([]);
  const [Docentes, setDocentes] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null); // Estado para almacenar el archivo seleccionado


  const [formData, setFormData] = useState({
    cedula: "",
    nombre: "",
    cedulapersona: "",
    nombrePersona: "",
    estado: "",
    observacion: "",
    fecha: "",
  });

  const [mensaje, setMensaje] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Actualiza el archivo seleccionado
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { cedula, nombre, cedulapersona, observacion, estado } = formData;

    // Crear FormData para enviar el archivo y otros datos
    const formDataToSend = new FormData();
    formDataToSend.append('cedula', cedula);
    formDataToSend.append('nombre', nombre);
    formDataToSend.append('cedulapersona', cedulapersona);
    formDataToSend.append('estado', estado);
    formDataToSend.append('observacion', observacion);
    formDataToSend.append('fecha', moment().tz('America/Bogota').format());
     // Validar tipo de archivo
  if (selectedFile) {
    if (selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg') {
      formDataToSend.append('foto', selectedFile);
    } else {
      console.error('Por favor selecciona un archivo PNG o JPEG.');
      setMensaje('Archivo inválido, solo se permiten PNG o JPEG.');
      return;
    }
  }

    try {
      await axios.post(
        `${config.baseURL}/api/v1/bitacora`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log('Registro de Bitacora Creado:', formData);

      setFormData({
        cedula: "",
        nombre: "",
        cedulapersona: "",
        nombrePersona: "",
        estado: "",
        observacion: "",
      });

      setSelectedFile(null); // Limpiar el archivo seleccionado después de enviar
      setMensaje('Formulario enviado');
    } catch (error) {
      console.error('Error al crear Bitacora:', error);
    }

    fetchBitacora();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const fetchBitacora = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/bitacora`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      setBitacora(response.data);
    } catch (error) {
      console.error('Error obteniendo Bitacora:', error);
    }
  };

  const fetchFuncionarios = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/funcionariostecnologia`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      setFuncionarios(response.data);
    } catch (error) {
      console.error('Error obteniendo Funcionarios:', error);
    }
  };

  const fetchPersonal = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/personal`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      setPersonal(response.data.data);
    } catch (error) {
      console.error('Error obteniendo Personal:', error);
    }
  };

  const fetchDocentes = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/docente`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      setDocentes(response.data.data);
    } catch (error) {
      console.error('Error obteniendo Docentes:', error);
    }
  };


  useEffect(() => {
    fetchBitacora();
    fetchFuncionarios();
    fetchPersonal();
    fetchDocentes();
  }, []);

  useEffect(() => {
    if (formData.cedula) {
      const funcionarioEncontrado = funcionarios.find(funcionario => funcionario.cedula === formData.cedula);
      if (funcionarioEncontrado) {
        setFormData(prevFormData => ({ ...prevFormData, nombre: funcionarioEncontrado.nombre }));
      } else {
        setFormData(prevFormData => ({ ...prevFormData, nombre: "" }));
      }
    } else {
      setFormData(prevFormData => ({ ...prevFormData, nombre: "" }));
    }

    if (formData.cedulapersona) {
      const personalEncontrado = Personal.find(personal => personal.cedula === formData.cedulapersona);
      const docenteEncontrado = Docentes.find(docente => docente.cedula === formData.cedulapersona);
    
      if (personalEncontrado) {
        setFormData(prevFormData => ({ ...prevFormData, nombrePersona: personalEncontrado.nombre }));
      } else if (docenteEncontrado) {
        setFormData(prevFormData => ({ ...prevFormData, nombrePersona: docenteEncontrado.nombre }));
      } else {
        setFormData(prevFormData => ({ ...prevFormData, nombrePersona: "" }));
      }
    } else {
      setFormData(prevFormData => ({ ...prevFormData, nombrePersona: "" }));
    }


  }, [formData.cedula, funcionarios, formData.cedulapersona, Personal, Docentes]);



  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
      <Header />
      <div className="flex flex-col flex-grow mx-2 my-20 sm:my-auto">
        <div className="mt-4 mb-4 flex flex-col flex-grow w-1/2 mx-auto bg-white rounded-lg shadow-md p-8 overflow-hidden" style={{ maxHeight: 'auto', height: 'auto' }}>
          <h1 className="text-[#0A6A50] mb-2 text-3xl font-semibold text-center">Bitácora</h1>
          <form onSubmit={handleSubmit} className='mt-4'>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="cedula" className="font-semibold text-gray-600">Cedula Contratista:</label>
                <input
                  type="text"
                  id="cedula"
                  name="cedula"
                  placeholder="Cedula"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={formData.cedula}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="nombre" className="text-sm font-semibold text-gray-600">Nombre:</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={formData.nombre}
                  onChange={handleChange}
                  readOnly
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="cedulapersona" className="font-semibold text-gray-600">Cedula Persona:</label>
                <input
                  type="text"
                  id="cedulapersona"
                  name="cedulapersona"
                  placeholder="Cedula de la Persona"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={formData.cedulapersona}
                  onChange={handleChange}
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="nombrePersona" className="text-sm font-semibold text-gray-600">Nombre Persona:</label>
                <input
                  type="text"
                  id="nombrePersona"
                  name="nombrePersona"
                  placeholder="Nombre de la Persona"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={formData.nombrePersona}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex items-center mt-4">
              <label className="font-semibold text-gray-600 mr-4">Novedad:</label>
              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="estado"
                    value="SALE"
                    checked={formData.estado === "SALE"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Sale
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="estado"
                    value="ENTRA"
                    checked={formData.estado === "ENTRA"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Entra
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="estado"
                    value="TRASLADO"
                    checked={formData.estado === "TRASLADO"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Traslado
                </label>

                <label className="flex items-center">
                  <input
                    type="radio"
                    name="estado"
                    value="INFORME"
                    checked={formData.estado === "INFORME"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Informe
                </label>
              </div>
            </div>

            <div className="flex flex-col mt-4 text-sm">
              <label htmlFor="observacion" className="text-sm font-semibold text-gray-600">Observaciones:</label>
              <textarea
                id="observacion"
                name="observacion"
                placeholder="Observaciones"
                className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                value={formData.observacion}
                onChange={handleChange}
                rows={4}
                cols={50}
              />
            </div>
{/* Añadir el campo de selección de archivo 
            <div className="flex flex-col mt-4">
              <label htmlFor="file" className="font-semibold text-gray-600">Seleccionar Archivo:</label>
              <input
                type="file"
                id="file"
                name="file"
                className="mt-2"
                onChange={handleFileChange}
              />
            </div>
*/}
            <div className="flex items-center justify-between mt-6">
              <button
                type="submit"
                className="py-2 px-4 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70] mb-4"
              >
                Enviar
              </button>
            </div>
          </form>
          {mensaje && <p className="mt-4 text-center text-sm text-gray-600">{mensaje}</p>}
        </div>
        
        <BitacoraRegistros bitacoras={bitacoras} />
      </div>
    </div>
  );
}

export default Bitacora;
