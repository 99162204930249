import React, { useState, useEffect } from "react";
import BitacorasInfo from "./BitacorasInfo";
import InformeBitacora from "./InformeBitcora";

const BitacoraRegistros = ({ bitacoras }) => {
  const [busqueda, setBusqueda] = useState('');
  const [resultadosFiltrados, setResultadosFiltrados] = useState(bitacoras);
  const [orden, setOrden] = useState('fecha-desc');
  
  // Actualiza los resultados filtrados cada vez que cambie la búsqueda o el orden
  useEffect(() => {
    const lowercasedFilter = busqueda.toLowerCase();
    const filteredData = bitacoras.filter((bitacora) => {
      const bitacoraData = JSON.stringify(bitacora).toLowerCase();
      // Filtra por palabras clave específicas
      return bitacoraData.includes(lowercasedFilter) || 
             bitacoraData.includes('salio') || 
             bitacoraData.includes('entro') || 
             bitacoraData.includes('traslado') || 
             bitacoraData.includes('novedad');
    });

    setResultadosFiltrados(filteredData);
  }, [busqueda, bitacoras]);

  // Ordena los registros de bitácora por fecha ascendente o descendente
  const ordenarBitacoras = (bitacoras) => {
    let bitacorasOrdenadas = [...bitacoras];
    bitacorasOrdenadas.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);
      return orden === 'fecha-asc' ? fechaA - fechaB : fechaB - fechaA;
    });
    return bitacorasOrdenadas;
  };

  const handleBusquedaChange = (event) => {
    setBusqueda(event.target.value);
  };

  const handleOrdenChange = (event) => {
    setOrden(event.target.value);
  };

  const bitacorasOrdenadas = ordenarBitacoras(resultadosFiltrados);

  return (
    <div className="flex flex-col items-center">
      <div className="w-1/2 p-12 px-6 py-10 sm:px-10 bg-white rounded-lg shadow-md lg:shadow-lg">
        <div className="flex flex-col">
          <label htmlFor="buscarBitacora" className="text-sm font-semibold text-gray-600">
            Buscar Bitácora:
          </label>
          <input
            type="text"
            id="busqueda"
            name="busqueda"
            placeholder="Buscar Bitácora"
            className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
            value={busqueda}
            onChange={handleBusquedaChange}
          />
        </div>

        <div className="flex flex-col mb-4 mt-4">
          <label htmlFor="ordenarPor" className="text-sm font-semibold text-gray-600">
            Ordenar por:
          </label>
          <select
            id="ordenarPor"
            className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
            value={orden}
            onChange={handleOrdenChange}
          >
            <option value="fecha-desc">Fecha más reciente</option>
            <option value="fecha-asc">Fecha más antiguo</option>
          </select>
        </div>

        <InformeBitacora />
      </div>

      <div className="grid grid-cols-3 mt-4">
        {bitacorasOrdenadas.map((bitacora, index) => (
          <div key={index}>
            <BitacorasInfo formData={bitacora} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BitacoraRegistros;
