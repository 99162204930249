import React, { useState } from 'react';
import axios from 'axios';
import Header from './Header';
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config

function Docente() {
  const [mensaje1, setMensaje] = useState('');
  const [mensaje2, setMensaje2] = useState('');

  const [formData, setFormData] = useState({
    cedula: "",
    nombre: "",
    tipocontrato: "",
    correo: "",
    telefono: "",
    facultad: "",
  });

  const [personalData, setPersonalData] = useState({
    cedula: "",
    nombre: "",
    dependencia: "",
    cargo: "",
    funcion: "",
    correo: "",
    telefono: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePersonalChange = (event) => {
    const { name, value } = event.target;
    setPersonalData({ ...personalData, [name]: value });
  };

  const handleSubmitDocente = async (e) => {
    e.preventDefault();
    const { cedula, nombre, tipocontrato, correo, telefono, facultad } = formData;
    try {
      await axios.post(`${config.baseURL}/api/v1/docente`, {
        cedula,
        nombre,
        tipocontrato,
        correo,
        telefono,
        facultad,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      console.log('Docente creado:', formData);

      setFormData({
        cedula: "",
        nombre: "",
        tipocontrato: "",
        correo: "",
        telefono: "",
        facultad: "",
      });

      setMensaje('Docente creado');
    } catch (error) {
      console.error('Error creando docente:', error);
    }
  }

  const handleSubmitPersonal = async (e) => {
    e.preventDefault();
    const { cedula, nombre, dependencia, cargo, funcion, correo, telefono } = personalData;
    try {
      await axios.post(`${config.baseURL}/api/v1/personal`, {
        cedula,
        nombre,
        dependencia,
        cargo,
        funcion,
        correo,
        telefono,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      console.log('Personal creado:', personalData);

      setPersonalData({
        cedula: "",
        nombre: "",
        dependencia: "",
        cargo: "",
        funcion: "",
        correo: "",
        telefono: "",
      });

      setMensaje2('Personal creado');
    } catch (error) {
      console.error('Error creando personal:', error);
    }
  }
  const dependencias = [
    "Admisiones, Registro y Control",
    "Bienestar Institucional",
    "Colegio Mayor",
    "Control Interno",
    "Docencia",
    "Gestión de Apoyos Educativos",
    "Gestión de Bienes y Servicios",
    "Gestión de Comunicaciones",
    "Gestión de Infraestructura",
    "Gestión de la Extensión Académica y la Proyección Social",
    "Gestión de la Mejora",
    "Gestión de Tecnología e Informática",
    "Gestión del Talento Humano",
    "Gestión Documental",
    "Gestión Financiera",
    "Gestión Legal",
    "Investigación",
    "Planeación Institucional"
  ];
  
  const facultades = [
    "Facultad de Administración",
    "Facultad de Arquitectura e Ingeniería",
    "Facultad de Ciencias de la Salud",
    "Facultad de Ciencias Sociales y Educación",
    "Vicerrectoría Académica",
    "Virtualidad",
    "Permanencia",
    "Vicerrectoría Investigación y Extensión"
  ];

  const tipodocente = [
    "Docentes De Catedra",
    "Docentes De Planta",
    "Docentes Ocasionales",
  ]

  const funcion = [
    "Administrativo",
    "Invitado",
    "No Aplica",
  ]

  const cargo = [
    "Contratista",
    "Vicerrector",
    "Decano",
    "Director",
    "Auxiliar",
    "Tesorero",
    "Servicios Generales",
    "Secretario",
    "Tecnico",
    "Profesional Universitario", 
  ]
  
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
      <Header />
      <div className="flex flex-col flex-grow mt-4">
        <div className="flex flex-col w-2/3 h-auto max-w-full mx-auto bg-white rounded-lg shadow-md p-8">
          <h1 className="text-[#0A6A50] mb-2 text-3xl font-semibold text-center">Crear Docente</h1>
          <form onSubmit={handleSubmitDocente} className='mt-4'>
            <div className="grid grid-cols-3 gap-4">
              <div className="flex flex-col">
                <label className="text-sm font-semibold text-gray-600">Nombre:</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={formData.nombre}
                  onChange={handleChange}
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="cedula" className="text-sm font-semibold text-gray-600">Cédula:</label>
                <input
                  type="text"
                  id="cedula"
                  name="cedula"
                  placeholder="Cédula"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={formData.cedula}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="flex flex-col">
  <label htmlFor="facultad" className="text-sm font-semibold text-gray-600">Facultad:</label>
  <select
    id="facultad"
    name="facultad"
    value={formData.facultad}
    onChange={handleChange}
    className="block w-full py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
  >
    <option value="" disabled>Seleccione una facultad</option>
    {facultades.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))}
  </select>
</div>

<div className="flex flex-col">
  <label htmlFor="tipocontrato" className="text-sm font-semibold text-gray-600">Tipo Contrato:</label>
  <select
    id="tipocontrato"
    name="tipocontrato"
    value={formData.tipocontrato}
    onChange={handleChange}
    className="block w-full py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
  >
    <option value="" disabled>Seleccione un tipo de contrato</option>
    {tipodocente.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))}
  </select>
</div>

              <div className="flex flex-col">
                <label htmlFor="telefono" className="text-sm font-semibold text-gray-600">Telefono:</label>
                <input
                  type="text"
                  id="telefono"
                  name="telefono"
                  placeholder="Telefono"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={formData.telefono}
                  onChange={handleChange}
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="correo" className="text-sm font-semibold text-gray-600">Correo:</label>
                <input
                  type="text"
                  id="correo"
                  name="correo"
                  placeholder="Correo"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={formData.correo}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button
              type="submit"
              className="py-2 px-4 mt-4 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70]"
            >
              Enviar
            </button>
          </form>
          {mensaje1 && <p className="mt-4 text-center text-sm text-green-700">{mensaje1}</p>}
          
        </div>

        <div className="flex flex-col w-2/3 h-auto max-w-full mx-auto bg-white rounded-lg shadow-md p-8 mt-2 mb-4">
          <h1 className="text-[#0A6A50] mb-2 text-3xl font-semibold text-center">Crear Personal</h1>
          <form onSubmit={handleSubmitPersonal} className='mt-4'>
            <div className="grid grid-cols-3 gap-4">
              <div className="flex flex-col">
                <label className="text-sm font-semibold text-gray-600">Nombre:</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={personalData.nombre}
                  onChange={handlePersonalChange}
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="cedula" className="text-sm font-semibold text-gray-600">Cédula:</label>
                <input
                  type="text"
                  id="cedula"
                  name="cedula"
                  placeholder="Cédula"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={personalData.cedula}
                  onChange={handlePersonalChange}
                  required
                />
              </div>

              <div className="flex flex-col">
  <label htmlFor="dependencia" className="text-sm font-semibold text-gray-600">Dependencia:</label>
  <select
    id="dependencia"
    name="dependencia"
    value={personalData.dependencia}
    onChange={handlePersonalChange}
    className="block w-full py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
  >
    <option value="" disabled>Seleccione una dependencia</option>
    {dependencias.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))}
  </select>
</div>

<div className="flex flex-col">
  <label htmlFor="cargo" className="text-sm font-semibold text-gray-600">Cargo:</label>
  <select
    id="cargo"
    name="cargo"
    value={personalData.cargo}
    onChange={handlePersonalChange}
    className="block w-full py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
  >
    <option value="" disabled>Seleccione un cargo</option>
    {cargo.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))}
  </select>
</div>

              <div className="flex flex-col">
  <label htmlFor="funcion" className="text-sm font-semibold text-gray-600">Función:</label>
  <select
    id="funcion"
    name="funcion"
    value={personalData.funcion}
    onChange={handlePersonalChange}
    className="block w-full py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
  >
    <option value="" disabled>Seleccione una función</option>
    {funcion.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))}
  </select>
</div>
              <div className="flex flex-col">
                <label htmlFor="telefono" className="text-sm font-semibold text-gray-600">Telefono:</label>
                <input
                  type="text"
                  id="telefono"
                  name="telefono"
                  placeholder="Telefono"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={personalData.telefono}
                  onChange={handlePersonalChange}
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="correo" className="text-sm font-semibold text-gray-600">Correo:</label>
                <input
                  type="text"
                  id="correo"
                  name="correo"
                  placeholder="Correo"
                  className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={personalData.correo}
                  onChange={handlePersonalChange}
                />
              </div>
            </div>
            <button
              type="submit"
              className="py-2 px-4 mt-4 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70]"
            >
              Enviar
            </button>
          </form>
         
          {mensaje2 && <p className="mt-4 text-center text-sm text-green-700">{mensaje2}</p>}
        </div>
      </div>
    </div>
  );
}

export default Docente;
