import React from 'react';

const ChabotInstructions = () => {

  return (
    <div className="mt-4 mb-8 flex flex-col flex-grow w-1/2 mx-auto bg-white rounded-lg shadow-md p-4 overflow-hidden">
      <h2 className="text-[#0A6A50] mb-2 text-2xl font-semibold text-center">Instrucciones del Chabot Corporativo</h2>
      
      <div className="mb-4">
        <p className="mb-2">
          Para consultar sobre el personal administrativo y contratistas utiliza la palabra <strong className='text-[#0A6A50] mb-2 text-xl font-semibold text-center'>"Personal"</strong>
        </p>
      
      </div>

      <div className="mb-4">
        <p className="mb-2">
          Para información sobre docentes, usa la palabra <strong className='text-[#0A6A50] mb-2 text-xl font-semibold text-center'>"Docentes"</strong>
        </p>
     
      </div>

      <div className="mb-4">
        <p className="mb-2">
          Para consultar sobre aulas, usa la palabra <strong className='text-[#0A6A50] mb-2 text-xl font-semibold text-center'>"Aulas"</strong>
        </p>
    
      </div>

      <div className="mb-4">
        <p className="mb-2">
          Para consultar sobre artículos, usa la palabra <strong className='text-[#0A6A50] mb-2 text-xl font-semibold text-center'>"Artículos"</strong>
        </p>
       
      </div>

      <div className="mb-4">
        <p className="mb-2">
          Para información sobre préstamos, usa la palabra <strong className='text-[#0A6A50] mb-2 text-xl font-semibold text-center'> "Préstamos"</strong>
        </p>
      
      </div>

      <div className="mb-4">
        <p className="mb-2">
          Para información sobre horarios, usa la palabra <strong className='text-[#0A6A50] mb-2 text-xl font-semibold text-center'>"Horarios"</strong>
        </p>
     
      </div>

      <p className="text-sm text-gray-600">
        Puedes usar estas palabras clave en el chatbot para obtener información sobre los diferentes temas que te interesan.
      </p>
    </div>
  );
};

export default ChabotInstructions;
