import React, { useState, useEffect } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config

const InformeBitacora = () => {
  const [bitacoras, setBitacora] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchBitacora = async () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/bitacora`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBitacora(response.data);
    } catch (error) {
      console.error("Error fetching prestamos:", error);
    }
  };

  useEffect(() => {
    fetchBitacora();
  }, []);

  const filterBitacorabyDate = (bitacoras) => {
    if (!startDate || !endDate) return bitacoras;
    const start = new Date(startDate);
    const end = new Date(endDate);
    return bitacoras.filter((bitacora) => {
      const prestamoDate = new Date(bitacora.fecha);
      return prestamoDate >= start && prestamoDate <= end;
    });
  };
  const generatePDF = async () => {
    setLoading(true);
  
    await fetchBitacora();
  
    const filteredPrestamos = filterBitacorabyDate(bitacoras);
    const doc = new jsPDF();
    const img = new Image();
    img.src = "/logo.png"; // Asegúrate de que la imagen del logo esté en tu proyecto y la ruta sea correcta
  
    img.onload = () => {
      // Agregar el logo
      doc.addImage(img, 'PNG', 14, 5, 60, 50); // Ajusta las posiciones y tamaños según sea necesario
  
      // Título del documento
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0); // Color negro para el texto
      doc.text("Colegio Mayor de Antioquia", 14, 50);
      doc.text("Informe de Bitácora", 14, 58);
  
      const tableColumn = [ "Código", "Atendido por:", "Fecha", "Novedad", "Observaciones", "Persona atendida:"];
      const tableRows = [];
      let contador = 1; // Inicia el contador desde 1
  
      filteredPrestamos.forEach((prestamo) => {
        const formattedDate = format(new Date(prestamo.fecha), 'EEEE d \'de\' MMMM \'del\' yyyy HH:mm', { locale: es });
  
        // Determine si el prestamo tiene un Docente o Personal y muestra su información
        const personaAtendida = prestamo.Docente 
          ? `${prestamo.Docente.nombre} ${prestamo.Docente.cedula} ${prestamo.Docente.facultad}` 
          : prestamo.Personal 
          ? `${prestamo.Personal.nombre} ${prestamo.Personal.cedula} ${prestamo.Personal.dependencia}` 
          : "";
  
        const prestamoData = [
          contador, // Muestra el número de orden
          prestamo.nombre || "",
          formattedDate || "",
          prestamo.estado || "",
          prestamo.observacion || "",
          personaAtendida
        ];
  
        tableRows.push(prestamoData);
        contador++; // Incrementa el contador
      });
  
      // Generar la tabla
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 65, // Ajustado para no solapar con el texto
        headStyles: {
          fillColor: [10, 106, 80], // Color RGB para el encabezado
          textColor: [255, 255, 255], // Color de texto blanco
          halign: 'center'
        },
        bodyStyles: {
          halign: 'center' // Alineación del texto de la tabla
        },
      });
      const today = format(new Date(), 'dd-MM-yyyy');
      // Descargar el PDF
      doc.save(`InformeBitacora-${today}.pdf`);
    };
  
    setLoading(false);
  };
  
  return (
    <div className="mt-4 flex justify-between items-center">
      <div className="flex space-x-2">
        <div className="flex flex-col">
          <label htmlFor="startDate" className="text-gray-800">Fecha Inicio</label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="py-2 px-3 text-gray-800 border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="endDate" className="text-gray-800">Fecha Fin</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="py-2 px-3 text-gray-800 border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
          />
        </div>
      </div>
      <button
        type="button"
        className="py-3 px-6 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70] mt-2"
        onClick={generatePDF}
        disabled={loading}
      >
        {loading ? "Generando Informe..." : "Informe"}
      </button>
    </div>
  );
};

export default InformeBitacora;
