import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config
import readXlsxFile from 'read-excel-file';
import Papa from 'papaparse';

const Pazysalvo = () => {
  const [prestamos, setPrestamos] = useState([]);
  const [resultado, setResultado] = useState(null);

  useEffect(() => {
    const fetchPrestamos = async () => {
      try {
        const response = await axios.get(`${config.baseURL}/api/v1/prestamo`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setPrestamos(response.data.data);
      } catch (error) {
        console.error("Error obteniendo los préstamos", error);
      }
    };

    fetchPrestamos();
  }, []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        readXlsxFile(file).then((rows) => {
          const cedulasFromFile = rows.slice(1).map(row => String(row[0])); // Convertir a String
         
          verificarCedulas(cedulasFromFile);
        });
      } else if (file.type === 'text/csv') {
        Papa.parse(file, {
          complete: (results) => {
            const cedulasFromFile = results.data.slice(1).map(row => String(row[0])); // Convertir a String
          
            verificarCedulas(cedulasFromFile);
          },
          header: false
        });
      } else {
        alert('Formato de archivo no soportado. Por favor, sube un archivo Excel o CSV.');
      }
    }
  };

  const verificarCedulas = (cedulas) => {
    console.log("Prestamos:", prestamos);
    const cedulasDeudoras = cedulas.filter(cedula => {
      return prestamos.some(prestamo => {
        const tieneDeuda = (prestamo.Docente && String(prestamo.Docente.cedula) === cedula) ||
                           (prestamo.Personal && String(prestamo.Personal.cedula) === cedula);
        return tieneDeuda && !prestamo.devuelto;
      });
    });
    setResultado(cedulasDeudoras);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
      <Header />
      <div className="flex-grow flex items-center justify-center py-10">
        <div className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
          <div className="text-center mb-6">
            <h1 className="text-4xl font-bold text-[#0A6A50]">Paz y Salvos</h1>
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-[#0A6A50]" htmlFor="file_input">Sube un archivo Excel en la primer columna ubica las cédulas</label>
            <input
              className="block w-full mt-2 text-sm text-white border border-[#0A6A50] rounded-lg cursor-pointer bg-green-50 focus:outline-none dark:bg-[#0A6A50] dark:border-gray-600 dark:placeholder-gray-400"
              id="file_input"
              type="file"
              onChange={handleFileUpload}
            />
          </div>
          {resultado && (
            <div className="text-[#0A6A50] mt-6">
              {resultado.length > 0 ? (
                <p className="text-[#0A6A50]">
                  Las cédulas {resultado.join(', ')} no pueden tener paz y salvo debido a que deben algo en el sistema.
                </p>
              ) : (
                <p className="text-green-600">
                  Todas las cédulas están al día.
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pazysalvo;
