import React, { useState, useEffect } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config
import { format } from 'date-fns';
import { es } from 'date-fns/locale'; // Para el formato en español

const Informe = () => {
  const [prestamos, setPrestamos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchPrestamos = async () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/prestamo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPrestamos(response.data.data);
    } catch (error) {
      console.error("Error fetching prestamos:", error);
    }
  };



  useEffect(() => {
    fetchPrestamos();
  }, []);

  const filterPrestamosByDate = (prestamos) => {
    if (!startDate || !endDate) return prestamos;
    const start = new Date(startDate);
    const end = new Date(endDate);
    return prestamos.filter((prestamo) => {
      const prestamoDate = new Date(prestamo.fecha);
      return prestamoDate >= start && prestamoDate <= end;
    });
  };
 
const generatePDF = async () => {
  setLoading(true);

  await fetchPrestamos();
  const filteredPrestamos = filterPrestamosByDate(prestamos);

  const doc = new jsPDF();
  const img = new Image();
  img.src = "/logo.png"; // Asegúrate de que la imagen del logo esté en tu proyecto y la ruta sea correcta

  img.onload = () => {
    // Agregar el logo
    doc.addImage(img, 'PNG', 14, 5, 60, 50); // Ajusta las posiciones y tamaños según sea necesario

    // Título del documento
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0); // Color negro para el texto
    doc.text("Colegio Mayor de Antioquia", 14, 50);
    doc.text("Informe de Préstamos", 14, 58);

    // Encabezados para la tabla
    const tableColumn = [
      "Docente",
      "Facultad",
      "Aula",
      "Fecha",
      { content: "Horario clase", colSpan: 2, styles: { halign: 'center' } }, // Nuevo encabezado para "Horario clase"
      "Llave",
      "Artículos",
      "Observacion",
    ];
    const subHeader = [
      "", // Vacío para Docente
      "", // Vacío para Facultad
      "", // Vacío para Aula
      "", // Vacío para Fecha
      "Inicio", // Subencabezado para Inicio
      "Fin", // Subencabezado para Fin
      "", // Vacío para Llave
      "", // Vacío para Artículos
      ""  // Vacío para Observacion
    ];

    const tableRows = [];

    // Agregar datos a la tabla
    filteredPrestamos.forEach((prestamo) => {
      const formattedDate = format(new Date(prestamo.fecha), 'dd/MM/yyyy HH:mm', { locale: es }); // Formato de fecha y hora
      const prestamoData = [
        prestamo.Docente?.nombre || prestamo.Personal?.nombre || "",
        prestamo.Docente?.facultad || prestamo.Personal?.dependencia || "",
        prestamo.Aula?.nombreAula || "",
        formattedDate, // Usar la fecha formateada
        prestamo.horainicio || "",
        prestamo.horafin || "",
        prestamo.llave || "",
        prestamo.ArticuloPrestamo.map((art) => art.Articulo.nombreArticulo).join(", "),
        prestamo.observaciones || "",
      ];
      tableRows.push(prestamoData);
    });

    // Generar la tabla con el nuevo encabezado y subencabezado
    doc.autoTable({
      head: [tableColumn, subHeader],
      body: tableRows,
      startY: 65, // Ajustado para no solapar con el texto
      headStyles: {
        fillColor: [10, 106, 80], // Color RGB para el encabezado
        textColor: [255, 255, 255], // Color de texto blanco
        halign: 'center'
      },
      bodyStyles: {
        halign: 'center' // Alineación del texto de la tabla
      },
      columnStyles: {
        4: { halign: 'center' }, // Centro para "Inicio"
        5: { halign: 'center' }  // Centro para "Fin"
      }
    });

    // Obtener la fecha de hoy en el formato dd-MM-yyyy
    const today = format(new Date(), 'dd-MM-yyyy');
    // Descargar el PDF con la fecha en el nombre
    doc.save(`informe_prestamos_${today}.pdf`);
  };
};
  
  return (
    <div className="mt-4 flex justify-between items-center">
    <div className="flex space-x-2">
      <div className="flex flex-col">
        <label htmlFor="startDate" className="text-gray-800">Fecha Inicio</label>
        <input
          type="date"
          id="startDate"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="py-2 px-3 text-gray-800 border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="endDate" className="text-gray-800">Fecha Fin</label>
        <input
          type="date"
          id="endDate"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="py-2 px-3 text-gray-800 border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
        />
      </div>
    </div>
    <button
      type="button"
      className="py-3 px-6 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70] mt-2"
      onClick={generatePDF}
      disabled={loading}
    >
      {loading ? "Generando Informe..." : "Informe"}
    </button>
  </div>
  );
};

export default Informe;
