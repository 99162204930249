import React, { useState } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const BitacorasInfo = ({ formData }) => {
  const [showPhoto, setShowPhoto] = useState(false);

  const {
    id,
    cedula,
    nombre,
    observacion,
    fecha,
    estado,
    Docente,
    Personal,
    fotoUrl,
  } = formData;

  // Función para formatear la fecha
  const formatDate = (dateString) => {
    if (!dateString) return "Fecha no especificada";
    const date = new Date(dateString);
    return format(date, "EEEE d 'de' MMMM 'del' yyyy HH:mm", { locale: es });
  };


  // Construir la URL completa de la foto
  const baseUrl = "http://localhost:3005"; // Cambia esto a la URL de tu servidor
  const fullPhotoUrl = fotoUrl ? `${baseUrl}${fotoUrl}` : null;


  return (
    <div className="p-12 px-6 py-10 sm:px-10 sm:py-6 bg-white rounded-lg shadow-md lg:shadow-lg mt-2 mx-2 mb-2">
      <h2 className="text-lg text-[#0A6A50] font-bold">Registro de Bitácora:</h2>
      <div className="justify-between grid grid-cols-1 mt-2 text-sm">
        <p>
          <span className="font-semibold">Código:</span> {id}
        </p>
        <p>
          <span className="font-semibold">Cédula:</span> {cedula || "Cédula no especificada"}
        </p>
        <p>
          <span className="font-semibold">Nombre:</span> {nombre || "Nombre no especificado"}
        </p>
        <p>
          <span className="font-semibold">Fecha:</span> {formatDate(fecha)}
        </p>
        <p>
          <span className="font-semibold">Estado:</span> {estado || "Estado no especificado"}
        </p>
        <p>
          <span className="font-semibold">Observaciones:</span> {observacion || "No especificadas"}
        </p>
        {Docente && (
          <p>
            <span className="font-semibold">Asignado a Docente:</span> {Docente.nombre} ({Docente.cedula})
          </p>
        )}
        {Personal && (
          <p>
            <span className="font-semibold">Asignado a Personal:</span> {Personal.nombre} ({Personal.cedula})
          </p>
        )}

       
        {/* Mostrar botón si hay una foto 
        {fotoUrl && (
          <button
            className="mt-4 text-sm text-white bg-[#0A6A50] rounded px-4 py-2 hover:bg-[#065f3f] focus:outline-none"
            onClick={() => setShowPhoto(true)}
          >
            Visualizar foto
          </button>
        )}
      */}
      </div>

      {/* Mostrar la foto en un modal o en pantalla cuando se presione el botón 
      {showPhoto && fotoUrl && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img
              src={fullPhotoUrl}
              alt="Foto de Bitácora"
              className="max-w-full max-h-[80vh] rounded-md"
            />
            <button
              className="mt-4 text-sm text-white bg-[#0A6A50] rounded px-4 py-2 hover:bg-[#065f3f] focus:outline-none"
              onClick={() => setShowPhoto(false)}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
      */}
    </div>
  );
};

export default BitacorasInfo;
