import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  // Obtener la información de la cuenta desde localStorage y parsearla
  const cuentaStr = localStorage.getItem('cuenta');
  let nombreCuenta = 'Usuario';
  if (cuentaStr) {
    const cuentaObj = JSON.parse(cuentaStr);
    nombreCuenta = cuentaObj.nombre;
  }

  return (
    <header>
      <nav className="border-gray-200 lg:px-6 py-2.5 bg-white">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          
          <div className="flex items-center lg:order-1">
            <img src="colmayor.png" className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-[#0A6A50] ">Colegio Mayor de Antioquia</span>
          </div>     
        
          <div className="flex items-center lg:order-2">
            <span className="text-[#0A6A50] text-sm font-semibold mr-2">{nombreCuenta}</span>
            <button onClick={() => navigate('/bitacora')} className="text-[#0A6A50] focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-100 focus:outline-none dark:focus:ring-gray-800">
              Bitacora
            </button>
            <button onClick={() => navigate('/reportes')} className="text-[#0A6A50] focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-100 focus:outline-none dark:focus:ring-gray-800">
              Reportes
            </button>
            <button onClick={() => navigate('/colmaia')} className="text-[#0A6A50] focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-100 focus:outline-none dark:focus:ring-gray-800">
              Colma-IA
            </button>
            <button onClick={() => navigate('/principal')} className="text-[#0A6A50] focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-100 focus:outline-none dark:focus:ring-gray-800">
              Prestamos
            </button>
            <button onClick={() => navigate('/docente')} className="text-[#0A6A50]   focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-100 focus:outline-none dark:focus:ring-gray-800">
              Agregar Usuario
            </button>
            <button onClick={handleLogout} className="text-[#0A6A50]  focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-100 focus:outline-none dark:focus:ring-gray-800">
              Cerrar Sesión
            </button>
          </div>

        </div>
      </nav>
    </header>
  );
};

export default Header;
