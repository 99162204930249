import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "tailwindcss/tailwind.css";
import Login from "./components/Login";
import Principal from "./components/Principal";
import Aula from "./components/Aulas";
import Colmaia from "./components/Colmaia";
import Reportes from "./components/Reportes";
import ProtectedRoute from "./components/ProtectedRoute"; // Asegúrate de importar ProtectedRoute
import Docente from "./components/Docente";
import Bitacora from "./components/Bitacora";
import Pazysalvo from "./components/Pazysalvo";
import "./App.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/principal" element={<ProtectedRoute><Principal /></ProtectedRoute>} />
          <Route exact path="/aulas" element={<ProtectedRoute><Aula /></ProtectedRoute>} />
          <Route exact path="/colmaia" element={<ProtectedRoute><Colmaia /></ProtectedRoute>} />
          <Route exact path="/reportes" element={<ProtectedRoute><Reportes /></ProtectedRoute>} />
          <Route exact path="/docente" element={<ProtectedRoute><Docente /></ProtectedRoute>} />
          <Route exact path="/bitacora" element={<ProtectedRoute><Bitacora /></ProtectedRoute>} />
          <Route exact path="/pazysalvo" element={<ProtectedRoute><Pazysalvo /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;